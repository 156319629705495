// import '../HomeScreenshots2/styles.scss';

import React, {memo, useMemo} from 'react';
import {AppScreenShotsComponent} from "../../types/ApiComponent";
import {WITH_API_BASE} from "../../configs/appConfigs";
import {Crown2, arrRight2, apple} from "../../images";
import Slider from "react-slick";
import {Link} from "gatsby";

type Props = {
    data: AppScreenShotsComponent,
    fullLink: string,
    pagePath: string
}

const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
};
const settingsMob = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1
};

const HomeScreenshots2 = ({data, fullLink, pagePath}: Props) => {
    const renderList = useMemo(() => {
        return data.Blocks.map((screenshot, index) => {
            return (
                <div className="appScreenshots2Screen" key={index}>
                    <div className="appScreenshots2Title">{screenshot?.Title || ''}</div>
                    <div className="appScreenshots2Image">
                        <img src={WITH_API_BASE(screenshot.Image.data.attributes.url)} alt=""/>
                    </div>
                </div>
            )
        })
    }, [data]);

    const renderMobileList = useMemo(() => {
        return data.Blocks.map((screenshot, index) => {
            return (
                <div className="appScreenshotsScreenMob" key={index}>
                    <div className="appScreenshotsScreenMobInner">
                        <div className="appScreenshotsTitle">{screenshot?.Title || ''}</div>
                        <div className="appScreenshotsImage">
                            {
                                screenshot?.MobImage?.data ? (
                                    <img src={WITH_API_BASE(screenshot.MobImage.data.attributes.url)} height={336} alt=""/>
                                ) : (
                                    <img src={WITH_API_BASE(screenshot.Image.data.attributes.url)} height={336} alt=""/>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        })
    }, [data])

    return (
        <>
            <div className="appScreenshots2" id={`slider${pagePath}`}>
                <Slider initialSlide={0} {...settings} className="appScreenshots2ScreenPc">
                    {renderList}
                </Slider>
            </div>

            <div className="container mobAppScreenshots2" id={`slider${pagePath}`}>
                <Slider initialSlide={0} centerMode variableWidth adaptiveHeight {...settingsMob} className="appScreenshots2ScreenMobile">
                    {renderMobileList}
                </Slider>
            </div>

            {/* <div className="buttonBlock">
                <Link to="/payment" className="btn btn-primary">
                    <img src={CrownImage} className="btn-img" width={21} alt=""/>
                    <span>Try it for $1</span>
                </Link>
            </div> */}

            {/* <div className="buttonBlock2">
                <Link to="/payment" className="btnv2 btn-primary">
                    <img src={Crown2} className="btn-img" width={30} alt=""/>
                    <span>Try AEER</span>
                    <img src={arrRight2} className="btn-img" width={30} alt=""/>
                </Link>
            </div> */}

            <div className="buttonBlock2">
                <Link to={fullLink} className="btnv2 btn-primary noEventsInside" id={`pageCorpTry${pagePath}BtnFirst`}>
                    <img src={Crown2} className="btn-img" width={30} alt=""/>
                    <span>Try AEER</span>
                    <img src={arrRight2} className="btn-img" width={30} alt=""/>
                </Link>
            </div>
            
        </>
    )
}

export default memo(HomeScreenshots2)
